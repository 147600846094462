<template>
    <div>
        <CommonTabsComponent
            :tabList="tabList"
            @tabChanged="tabChanged"
        ></CommonTabsComponent>
        <PowerBI
            :projectId="ProjectID"
            :reportId="reportId"
            v-if="reportId"
        ></PowerBI>
        <div v-else>No reports found</div>
    </div>
</template>

<script>
import { datasetService } from '../../services/dataset.service';
import { mapState } from 'vuex';
export default {
    components: {
        CommonTabsComponent: () => import('./CommonTabsComponent.vue'),
        PowerBI: () => import('../PowerBI/PowerBI.vue'),
    },
    data() {
        return {
            tabList: [],
            reportId: 0,
        };
    },
    methods: {
        tabChanged(newTab) {
            let newReport = this.tabList.find(
                (report) => report.name === newTab.name
            );
            this.reportId = newReport.reportId;
        },
        getReports() {
            this.tabList = [];
            datasetService
                .getPowerBIDatasets(this.ProjectID)
                .then((res) => {
                    res.data.forEach((report) => {
                        this.tabList.push({
                            name: report[0].DatasetAlias,
                            reportId: report[0].DatasetId,
                        });
                    });
                    if (this.tabList.length > 0) {
                        this.reportId = this.tabList[0].reportId;
                    } else {
                        this.reportId = 0;
                    }
                })
                .catch((err) => {});
        },
    },
    computed: {
        ...mapState({
            ProjectID: function(state) {
                return state.projects.selectedProjects[0].ProjectID;
            },
        }),
    },
    created() {
        this.getReports();
    },
    watch: {
        ProjectID() {
            this.getReports();
        },
    },
};
</script>

<style lang="scss" scoped></style>
